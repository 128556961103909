<template>
  <div>
    <h4>ยินดีต้อนรับสู่หลังบ้าน HONDA DCKK</h4>
    <p>คำแนะนำในการอัปโหลดไฟล์ Excel</p>

    <b-row
      cols="1"
      cols-md="2"
    >
      <b-col>
        <b-card no-body>
          <b-card-header>
            <b-card-title> รูปแบบไฟล์ Excel รถ </b-card-title>
          </b-card-header>
          <b-card-body>
            <dl
              v-for="rule in keyUploadFileMotorbikeRequired"
              :key="rule.key"
              class="row"
            >
              <dt class="col-sm-5">{{ rule.key }}</dt>
              <dd class="col-sm-7">{{ rule.name }}</dd>
            </dl>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col>
        <b-card no-body>
          <b-card-header>
            <b-card-title> รูปแบบไฟล์ Excel เรท </b-card-title>
          </b-card-header>
          <b-card-body>
            <dl
              v-for="rule in keyUploadFileRateRequired"
              :key="rule.key"
              class="row"
            >
              <dt class="col-sm-5">{{ rule.key }}</dt>
              <dd class="col-sm-7">{{ rule.name }}</dd>
            </dl>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      keyUploadFileMotorbikeRequired: [
        { key: 'MODEL', name: 'โมเดล' },
        { key: 'FRAMENUMBER', name: 'หมายเลขเฟรม' },
        { key: 'SIZE', name: 'ไซส์ (S,M,L)' },
        { key: 'MOTORBIKE_EN', name: 'ชื่อรถอังกฤษ' },
        { key: 'MOTORBIKE_TH', name: 'ชื่อรถไทย' },
        { key: 'PRICE', name: 'ราคา' },
        { key: 'COSTPRICE', name: 'ต้นทุน' },
        { key: 'TRANFERCOST', name: 'ต้นทุนขนส่ง' },
        { key: 'IMAGEID', name: 'รูป ID' },
        { key: 'REGISTERCOST', name: 'ต้นทุน หมายเลขทะเบียน' },
        { key: 'ACTCOST', name: 'ต้นทุน พรบ' },
        { key: 'USABLE', name: 'แสดงหน้าค้นหา (0 ปิด, 1 เปิด)' },
      ],
      keyUploadFileRateRequired: [
        { key: 'MODELCODE', name: 'โมเดลรถ' },
        { key: 'CODE', name: 'รหัสอ้างอิงไฟแนนซ์' },
        { key: 'YODJUD', name: 'ยอดจัด' },
        { key: 'DEFAULT', name: 'ออกรถเริ่มต้น' },
      ],
    }
  },
}
</script>

<style lang="scss" scoped></style>
